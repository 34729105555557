import React, {useEffect, useState} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import config from "../../config/main.config";
import {getFromLocalStorage, getTokenFromLocalStorage} from "../../helper/util";

export {
    fetchFrontendIdImage,
    deleteImage,
    selectImage,
    editImage,
    getTokenFromLocalStorage,
    getFromLocalStorage,
    getAlt,
    getFilteredImages,
    useWindowSize
}

function fetchFrontendIdImage(id) {
    return new Promise(resolve => fetch(config.BASE_URL + "images/single/" + id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json)
            });
        }
    })).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                window.history.replace("/admin-login");
            }
        }
    );
}


function deleteImage(id, setImage) {
    fetch(config.BASE_URL + "images/single/", {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({frontendId: id})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            fetchFrontendIdImage(id).then(image => setImage(image));
        } else {
            //TODO: Alert?
        }
    }).catch(
        error => {
            console.error(error);
        }
    );
}

function selectImage(link, id, setImage, setSelectModal, altText) {
    //put links to component
    fetch(config.BASE_URL + 'images/single-selection/', {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        },
        body: JSON.stringify({frontendId: id, link: link, alt: altText})
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            fetchFrontendIdImage(id, setImage, altText).then(() => {
            });
            setSelectModal(false);
        }
    }).catch(() => {
    });
}

function editImage(id, image, altText) {
    let body = {
        frontendId: id,
        link: image.link,
        alt: altText
    };
    return new Promise(resolve => fetch(config.BASE_URL + 'images/', {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getFromLocalStorage("token")
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json.image);
            })
        }
    }).catch((e) => {
        console.error(e);
    }));
}

function getFilteredImages(allImages, imageFilter, imagePath, id, setImage, altText, setSelectModal) {
    let filteredImages = [];
    for (let i = 0; i < allImages.length; i++) {
        let item = allImages[i];
        if (item.link.toLowerCase().includes(imageFilter.toLowerCase())) {
            filteredImages.push(
                <Grid.Column key={i} width={5}>
                    <img src={config.BASE_URL_IMAGES + 'all-images/' + item.link}
                         style={item.link.includes(".webp") ? {border: 'blueviolet',borderStyle: 'solid', borderWidth:'5px'} : {}}
                         alt={item.alt}
                         onClick={_ => {
                             editImage(id, item, altText).then(image => {
                                 setImage(image);
                                 setSelectModal(false);
                             })
                         }}
                    />
                </Grid.Column>
            );
        }
    }
    return filteredImages;
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({width: undefined, height: undefined});
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.outerWidth,
                height: window.outerHeight,
            });

        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

function getAlt(id, setAltText) {
    fetch(config.BASE_URL + "images/single/" + id, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setAltText(json.alt);
            });
        }
    }).catch(
        error => {
            console.error(error);
            if (error.status === 401) {
                window.history.replace("/admin-login");
            }
        }
    );
}