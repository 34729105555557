import React, {useEffect, useState} from 'react';
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import config from "../../config/main.config";
import EditTextComponent from "../editText/EditTextComponent";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import EditHTMLComponent from "../editHTML/EditHTMLComponent";
import EditImageComponent from "../editImage/EditImageComponent";
import i18n from "i18next";

const SupportCard = () => {
    const [textStore, setTextStore] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadTextsByNamespace(config.BASE_URL, 'supportCard').then((text) => {
            setTextStore(text);
            setLoaded(true);
        })
    }, []);

    return (
        <div className={'sixteen wide column computer fifteen wide mobile page-content-wrapper'}>
            <Card className={'card-container margin-auto'} style={{width: '100%', padding: '10rem', bottom: '-150px'}}>
                {loaded ?
                    <Card.Content textAlign={'center'}>
                        <h1>
                            <EditTextComponent textStore={textStore} id={'supportCard-header-1'}
                                               namespace={'supportCard'}/>
                        </h1>
                        <p className={'text'} style={{
                            marginTop: '2rem'
                        }}>
                            <EditHTMLComponent textStore={textStore} id={'supportCard-text-1'}
                                               namespace={'supportCard'}/>
                        </p>
                        <div className={'grid ui'}>
                            <div className={'row centered'} style={{paddingTop: '8rem'}}>
                                {/*<div className={'five wide computer sixteen wide mobile column'}
                                      style={{display: 'grid'}}>
                                    <a rel={'noreferrer'} target={'_self'} style={{color: "black"}}
                                       href={`/${i18n.language}/ehrenamt`}>
                                        <div className={'icon-card-container'}>
                                            <EditImageComponent id={'supportCard-image-1'}/>
                                        </div>
                                        <p className={'icon-text'}><EditTextComponent textStore={textStore}
                                                                                      id={'supportCard-subheader-1'}
                                                                                      namespace={'supportCard'}/>
                                        </p>
                                    </a>
                                </div>*/}
                                <div className={'eight wide computer sixteen wide mobile column'}
                                     style={{display: 'grid'}}>
                                    <a rel={'noreferrer'} target={'_blank'} style={{color:"black"}}
                                       href={config.URL + 'downloads/Hospiz_Mitglied_Formular.pdf'}>
                                        <div className={'icon-card-container'}>
                                            <EditImageComponent id={'supportCard-image-2'}/>
                                        </div>
                                        <p className={'icon-text'}>
                                            <EditTextComponent textStore={textStore}
                                                               id={'supportCard-subheader-2'}
                                                               namespace={'supportCard'}/>
                                        </p>
                                    </a>
                                </div>
                                <div className={'eight wide computer sixteen wide mobile column'}
                                     style={{display: 'grid'}}>
                                    <a rel={'noreferrer'} target={'_self'} style={{color:"black"}}
                                       href={`/${i18n.language}/donate`}>
                                    <div className={'icon-card-container'}>
                                        <EditImageComponent id={'supportCard-image-3'}/>
                                    </div>
                                    <p className={'icon-text'}><EditTextComponent textStore={textStore}
                                                                                  id={'supportCard-subheader-3'}
                                                                                  namespace={'supportCard'}/>
                                    </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Card.Content> : null}
            </Card>
        </div>
    )
}

export default SupportCard;