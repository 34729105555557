import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import config from "../config/main.config";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import {jumpToTop, MAX_MOBILE_WIDTH, UseWindowSize} from "../helper/util";
import EditTextComponent from "../components/editText/EditTextComponent";
import EditHTMLComponent from "../components/editHTML/EditHTMLComponent";
import EditImageComponent from "../components/editImage/EditImageComponent";
import SupportCard from "../components/supportCard/SupportCard";
import {loadTextsByNamespace} from "../../server/helper/ssrHelper";
import ContactPage from "./contact/Contact";


const EhrenamtPage = () => {
    let applicationWidth = UseWindowSize().width;

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);

    const namespace = 'ehrenamt'

    return (
        <div className={'grid ui'} style={{padding: 0, background: '#FAFAFA'}}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Ehrenamt', url: '/ehrenamt'}]}/>
            </div>
            <div
                className={'row top-image-cut'}
                style={{backgroundImage: "url(" + config.BASE_URL_IMAGES + "StiftungTopImage.jpg)"}}>
                <img src={'/images/bows/Bogen6_Beige.svg'} alt={'bogen'}
                     className={'bow-6-position'}/>
            </div>
            <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide computer fourteen wide mobile column  stiftung-card-container'}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position stiftung-card-position'}>
                            <Card.Header className={'card-header'} s>
                                <h1><EditTextComponent id={`${namespace}-card-header-1`} namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-text-1`} namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-header-1`} namespace={namespace}/></h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-text-1`} namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered stiftung-colored-section page-content-wrapper'}>
                <div className={'fourteen wide computer twelve wide mobile column margin-top-bottom'}
                     style={{paddingTop: '4rem'}}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <p>
                        <EditHTMLComponent id={`${namespace}-text-2`} namespace={namespace}/>
                    </p>
                </div>
                <div className={'six wide computer fourteen wide mobile column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-1`}/>
                </div>
                <div className={'ten wide computer fourteen wide mobile column '} style={{minHeight:'500px'}}>
                   <ContactPage type={'stationaer'}/>
                </div>
            </div>
            <div className={'row centered'} style={{background: '#7C962E'}}>
                <img src={'/images/bows/Bogen4_beige.svg'} alt={'bogen'}
                     className={'bow-4-position'}/>
                <SupportCard/>
            </div>
        </div>
    )
}

EhrenamtPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'ehrenamt').then((text) => {
        return text;
    })
}

export default withTranslation()(EhrenamtPage);